(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/image-v2/assets/javascripts/helpers/hbs-helpers.js') >= 0) return;  svs.modules.push('/components/content/image-v2/assets/javascripts/helpers/hbs-helpers.js');

'use strict';

var _require;
const loggerId = 'components:content:image-v2';
const cmsImagePrefix = '/cms/images';
const {
  isServer
} = svs;
const {
  svsuid,
  getFileFormat
} = svs.utils;
const trinidad = isServer ? (_require = require('trinidad-core')) === null || _require === void 0 ? void 0 : _require.core : null;
const {
  toArrayOrNull
} = isServer ? require("".concat(__dirname, "/to-array-or-null.es6")) : svs.content.image_v2.helpers;
const logger = isServer ? trinidad.logger(loggerId) : new svs.core.log.Logger(loggerId);

const getUrlWithCorrectHost = (url, config) => {
  if (!url) {
    return '';
  }

  const isAbsolute = /^(https?:)?\/\/.*/.test(url);

  const encodeFilename = function (_fullMatch, name) {
    let ext = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
    return "".concat(encodeURIComponent(decodeURIComponent(name))).concat(ext);
  };

  if (isAbsolute) {
    return url.replace(/([^/]+?)(\.[^/.]+)?(?=[?#]|$)/, encodeFilename);
  }

  return "".concat(config.options.cdn2).concat(url.replace(/([^/]+?)(\.[^/.]+)?(?=[?#]|$)/, encodeFilename));
};

const getUrlWithFilledPlaceholders = (url, width, extension) => {
  const picassoData = "".concat(width, "/").concat(extension);
  return url.replace(new RegExp("^".concat(cmsImagePrefix)), "".concat(cmsImagePrefix, "/").concat(picassoData));
};

const getWithFilledPlaceholders = (url, width, extension) => url.replace('{width}', width).replace('{extension}', extension);

const isOriginalImage = image => {
  if (!(image !== null && image !== void 0 && image.url)) {
    return false;
  }
  const fileFormat = getFileFormat(image.url);
  return Boolean((image === null || image === void 0 ? void 0 : image.originalImage) || (fileFormat === null || fileFormat === void 0 ? void 0 : fileFormat.extension) === 'svg');
};

const getImageProminentColor = image => (image === null || image === void 0 ? void 0 : image.dominantColor) || null; 

const getUrl = (image, extension, width, isFallbackImg) => {
  var _image$url, _extension;
  let url = (image === null || image === void 0 || (_image$url = image.url) === null || _image$url === void 0 ? void 0 : _image$url.replace(/-({width}|%7Bwidth%7D)/g, '')) || '';
  if (isFallbackImg || isOriginalImage(image)) {
    return url;
  }
  if (image !== null && image !== void 0 && image.isPreview && !extension) {
    return url;
  }
  url = getWithFilledPlaceholders(url, width, extension);
  extension = ((_extension = extension) === null || _extension === void 0 ? void 0 : _extension.replace(/\./g, '')) || 'original';
  return getUrlWithFilledPlaceholders(url, width, extension);
};

const getSrcsetString = (image, extension, width, config) => {
  let output = getUrl(image, extension || 'auto', width);
  output = getUrlWithCorrectHost(output, config);
  return "".concat(output).concat(isNaN(width) ? '' : " ".concat(width, "w"));
};

function registerHelpers(hbs, config) {
  const helpers = {
    getUrlWithCorrectHost,
    contentImageHeight: _ref => {
      let {
        height,
        width
      } = _ref;
      return width && height ? height / width * 100 : 43.75;
    },
    getUniqueSelector: () => "image-".concat(svsuid()),
    isOriginalImage,
    getImageProminentColor,
    getSrcset: (image, extension) => {
      var _image$availableWidth;
      const srcsetArray = (image === null || image === void 0 || (_image$availableWidth = image.availableWidths) === null || _image$availableWidth === void 0 ? void 0 : _image$availableWidth.map(width => getSrcsetString(image, extension, width, config))) || getUrlWithCorrectHost(getUrl(image, extension), config);
      return new hbs.SafeString(srcsetArray.toString());
    },
    setVar(name, value) {
      this[name] = value;
    },
    getSourceElementsData: image => {
      if (!image) {
        logger.warn('No image data provided.');
        return [];
      }
      const {
        media,
        sizes: imageSizes
      } = image;
      const sizes = imageSizes || '100vw';
      const srcSet = helpers.getSrcset(image, 'auto');
      return [{
        id: svsuid(),
        media,
        sizes,
        srcSet
      }];
    },
    stringifyImagesObject: input => {
      const imagesArray = toArrayOrNull(input);
      if (!imagesArray) {
        return null;
      }
      return JSON.stringify(imagesArray.map(item => item && {
        aspectRatio: item.aspectRatio,
        dominantColor: item.dominantColor,
        media: item.media
      }));
    },
    setStyleElement(selector, imagesObject) {
      if (svs.isBrowser) {
        const {
          setStyleElement
        } = svs.content.image_v2.helpers.multiImageStyleElement;
        setStyleElement(selector, imagesObject);
      }
    },
    getNoscriptUrl(image, images, noscriptUrl, isMultiImage) {
      const getWidestItem = items => (items === null || items === void 0 ? void 0 : items.reduce((prev, curr) => (prev === null || prev === void 0 ? void 0 : prev.width) > (curr === null || curr === void 0 ? void 0 : curr.width) ? prev : curr, {
        width: 0
      })) || null;
      const fallbackImage = isMultiImage ? getWidestItem(Object.values(images)) : image;
      const url = noscriptUrl || getUrl(fallbackImage, null, null, true);
      return getUrlWithCorrectHost(url, config);
    }
  };
  Object.entries(helpers).forEach(_ref2 => {
    let [name, fn] = _ref2;
    return hbs.registerHelper(name, fn);
  });
}
if (isServer) {
  const {
    core
  } = require('trinidad-core');
  registerHelpers(require('hbs'), core.config);
} else {
  var _svs$core;
  const config = {
    options: (_svs$core = svs.core) === null || _svs$core === void 0 || (_svs$core = _svs$core.config) === null || _svs$core === void 0 || (_svs$core = _svs$core.data) === null || _svs$core === void 0 ? void 0 : _svs$core.svsconfig
  };
  registerHelpers(Handlebars, config);
}

 })(window);